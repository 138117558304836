import { Injectable } from '@angular/core';
import { StorageService } from '@common/services/storage.service';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { NotificationService } from '@common/co/core/services/notification.service';
import { getSplNotification } from '@common/co/core/helpers/spl-notification';
import { PlatformService } from '@common/co/core/services/platform.service';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import {
  PushClient,
  PushDeviceRegistrationCommand,
  PushNotificationDeviceType,
  DeviceRegistrationResponse,
  PushDeviceUnRegistrationCommand,
} from '@common/services/co-api-client';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { LoggingService } from '@common/services/logging.service';
import { SplPushNotification } from '@common/co/core/models/splNotification.model';
import { applicationStorageFields } from '@common/co/core/app-storage.constants';

@Injectable()
export class PushNotificationsService {
  constructor(
    private notificationService: NotificationService,
    private _platformService: PlatformService,
    private pushClient: PushClient,
    private busService: AppBusService,
    private authorizeService: AuthorizeService,
    private loggingService: LoggingService,
    private storage: StorageService,
  ) {}

  public async getDeviceType(): Promise<PushNotificationDeviceType> {
    if (await this._platformService.isAndroid()) {
      return PushNotificationDeviceType.Android;
    }
    if (await this._platformService.isIos()) {
      return PushNotificationDeviceType.Ios;
    }

    return PushNotificationDeviceType.Web;
  }
  public async initialize(): Promise<void> {
    this.authorizeService.initialized$.subscribe(() => {
      this.authorizeService
        .isAuthorizationExpired()
        .then((isExpired: boolean) => {
          if (!isExpired) {
            this.registerPush();
          }
        });
    });
    this.busService.login$.subscribe(() => {
      this.registerPush();
    });
    this.busService.logoutRequested$.subscribe(() => {
      this.unregisterPush();
    });
  }

  public async registerPush(): Promise<any> {
    await this.unregisterPush();
    await this.createPushSubscriptions();
  }

  public async unregisterPush(): Promise<any> {
    if ((await this.getDeviceType()) === PushNotificationDeviceType.Web) {
      this.busService.unregisteredPush();
      return;
    }
    PushNotifications.removeAllListeners();
    const installationId = await this.storage.get({
      key: applicationStorageFields.STORAGE_INSTALLATION_ID,
    });
    if (installationId && installationId.value) {
      await this.pushClient
        .unregister(
          new PushDeviceUnRegistrationCommand({
            installationId: installationId.value,
          }),
        )
        .toPromise()
        .catch((e) => {
          this.busService.unregisteredPush();
          throw e;
        });
    }
    this.busService.unregisteredPush();
  }
  private async createPushSubscriptions(): Promise<any> {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    if ((await this.getDeviceType()) === PushNotificationDeviceType.Web) {
      return;
    }

    // On success, we should be able to receive notifications
    PushNotifications.addListener(
      'registration',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      async (token: Token) => {
        const msg1 = `Device register ${token.value}`;
        console.log(msg1);

        this.pushClient
          .register(
            new PushDeviceRegistrationCommand({
              registrationId: token.value,
              deviceType: await this.getDeviceType(),
            }),
          )
          .toPromise()
          .then(async (data: DeviceRegistrationResponse) => {
            const msg2 = `Device registered ${data.installationId}`;
            console.log(msg2);
            await this.storage.set({
              key: applicationStorageFields.STORAGE_INSTALLATION_ID,
              value: data.installationId,
            });
          })
          .catch(async (error: any) => {
            const msg = `Push notification register error ${error.message}`;
            console.log(msg);
            await this.storage.set({
              key: applicationStorageFields.STORAGE_INSTALLATION_ID,
              value: null,
            });
          });
      },
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener(
      'registrationError',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      (error: any) => {
        const msg = `Push notification registration error: ${JSON.stringify(
          error,
        )}`;
        console.log(msg);
      },
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        const splNotification = getSplNotification(
          'push',
          notification.body,
          '',
          notification,
          'push',
        );
        this.notificationService.generateNotification(splNotification);
      },
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        // ios and android has different structure of payload
        this.loggingService.logEvent('pushNotificationActionPerformed', {
          notification: JSON.stringify(notification),
        });
        let data: any;

        if (await this._platformService.isIos()) {
          data = notification.notification.data.aps;
        } else {
          data = notification.notification.data;
        }
        if (!data) {
          return;
        }
        const pushNotification = new SplPushNotification(
          notification.notification.id,
          data,
          notification.notification.title,
          data.message,
        );
        this.notificationService.resolve(pushNotification);
      },
    );

    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
        const msg = `Push notification permissions ${result.receive}`;
        console.log(msg);
      }
    });
  }
}
