export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'https://localhost:5001',
  identityServerUrl: 'https://localhost:5001',
  appInsights: {
    instrumentationKey: 'b7f20237-f99f-4c56-aae6-4c1766e3a63e',
  },
  verbosityLevel: 0, // Trace = 0, Information = 1, Metric = 2, Warning = 3, Error = 4,None = 99
  documentsSAConnectionString:
    'BlobEndpoint=https://spltestartifactssadev.blob.core.windows.net/;QueueEndpoint=https://spltestartifactssadev.queue.core.windows.net/;FileEndpoint=https://spltestartifactssadev.file.core.windows.net/;TableEndpoint=https://spltestartifactssadev.table.core.windows.net/;SharedAccessSignature=sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-07-30T16:57:57Z&st=2022-07-26T08:57:57Z&spr=https,http&sig=eYS44T25S%2F%2B4w8mJIOeci9ijMsvcj7SVUl5uieQSNWI%3D',
  bitmovinPlayerKey: 'b3696cbe-454f-4a3f-906e-b08e24005726',
};
